<template>
  <div class="visitor-book-reply" ref="scroller">
    <van-loading v-show="loading" color="rgb(0, 64, 152)"></van-loading>
    <item :data="comment" ref="message" :action="false" :list-all="true">
      <!-- :style="{height: 'calc(100% - '+ ui.footerHeight +'px)'}" -->
      <!-- <template #button>
        <div class="item-button" @click="colseComment">关闭</div>
      </template> -->
    </item>

    <div
      class="layout-panel__footer"
      :style="{ marginTop: -ui.footerHeight - 5 + 'px', height: ui.footerHeight + 'px' }"
    >
      <comment-footer :id="comment.id" @replySuccess="appendNewMessage" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getCommentList } from '@/api/comments.js'
import { Loading, Notify } from 'vant'

export default {
  name: 'VisitorReply',
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      loading: true,
      comment: {}
    }
  },
  computed: {
    ...mapState(['ui']),
    ...mapGetters(['userData'])
  },
  watch: {
    id: {
      immediate: true,
      handler (value) {
        if (value) {
          this.getDetail()
        }
      }
    }
  },
  components: {
    Item: () => import('./components/Item.vue'),
    CommentFooter: () => import('@/views/VisitorBook/components/Footer'),
    [Loading.name]: Loading
  },
  methods: {
    /**
     * 获取数据的详情
     */
    getDetail () {
      this.loading = true
      getCommentList({
        userId: this.userData.userId
      })
        .then(res => {
          // eslint-disable-next-line
          this.comment = res.data.find(item => item.id == this.id)
          if (!this.comment) {
            Notify({ type: 'danger', message: '未找到留言' })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    /**
     * 从接口拉去新的数据放到当前的数据中
     */
    appendNewMessage () {
      getCommentList({
        userId: this.userData.userId
      }).then(res => {
        // eslint-disable-next-line
        const target = res.data.find(item => item.id == this.id)
        const leaveDetails = this.comment.leaveDetails
        const newLeaveDetails = target.leaveDetails
        // 比对数据获取新的数据
        const last = leaveDetails[leaveDetails.length - 1]
        // 获取到新的数据开始索引
        const index = newLeaveDetails.findIndex(item => item.id === last.id)
        leaveDetails.splice(
          leaveDetails.length,
          0,
          ...newLeaveDetails.slice(index + 1)
        )
        Notify({
          type: 'success',
          message: '留言回复成功'
        })
        setTimeout(this.scrollToBottom, 300)
      })
    },
    /**
     * 滚动显示最新的消息
     */
    scrollToBottom () {
      this.$refs.scroller.scrollTop = this.$refs.message.$el.offsetHeight
    }
  }
}
</script>

<style lang="scss" scoped>
.visitor-book-reply {
  height: 100%;
  overflow: auto;
  padding: 0 10px;
  .van-loading {
    position: fixed;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .visitor-item {
    min-height: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
  }
}
</style>
