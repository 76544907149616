// 用户留言
import request from './serve'
import qs from 'qs'

// 获取留言列表
export const getCommentList = params => {
  return request({
    url: '/api/comments/findAll',
    method: 'get',
    params
  })
}

// 提交新的留言部分
export const submitComment = data => {
  return request({
    url: '/api/comments/save',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data' // 含有附件的表单提交
    },
    transformRequest: [
      input => {
        input = qs.parse(input)
        // 把数据转成 formData 进行传输
        const formData = new FormData()
        for (const key in input) {
          key !== 'files' && formData.append(key, input[key])
        }
        // 文件列表依次添加到表单数据中
        if (data.files && data.files.length) {
          data.files.forEach(item => formData.append('files', item))
        }
        return formData
      }
    ]
  })
}

// 获取参保地
export const getInstAreas = params => {
  return request({
    url: '/api/division/findAll',
    method: 'get',
    params
  })
}

// 获取问题分类
export const getCategory = params => {
  return request({
    url: '/api/knowledgeBase/getCategory',
    method: 'get',
    params
  })
}

// 留言回复
export const replyComment = data => {
  return request({
    url: '/api/comments/reply',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data' // 含有附件的表单提交
    },
    transformRequest: [
      input => {
        input = qs.parse(input)
        // 把数据转成 formData 进行传输
        const formData = new FormData()
        for (const key in input) {
          key !== 'files' && formData.append(key, input[key])
        }
        data.files && formData.append('files', data.files)
        return formData
      }
    ]
  })
}

/**
 * 关闭留言
 */
export const colseComment = params => {
  return request({
    url: '/api/comments/closeById',
    method: 'get',
    params
  })
}

/**
 * 删除留言
 */
export const removeComment = params => {
  return request({
    url: '/api/comments/deleteById',
    method: 'get',
    params
  })
}
